// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <StaticImage
//       src="../images/gatsby-astronaut.png"
//       width={300}
//       quality={95}
//       formats={["AUTO", "WEBP", "AVIF"]}
//       alt="A Gatsby astronaut"
//       style={{ marginBottom: `1.45rem` }}
//     />
//     <p>
//       <Link to="/page-2/">Go to page 2</Link> <br />
//       <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
//     </p>
//   </Layout>
// )

// export default IndexPage


import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { Container } from "react-bootstrap"

const IndexPage2 = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: { eq: "staffphoto.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 50, 
              webpOptions: {quality: 50}, 
              width: 2000,
              placeholder: BLURRED,
              blurredOptions: {width: 1000},
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);
  // const crowdImage = getImage(crowd);

  return (
    <Layout>
      <SEO title="About Us" />
      <BgImage image={pluginImage} className="masthead">
        <div className="color-overlay d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <h1>ECI at your Service</h1>
              
            </Container>
          </div>
        </div>
      </BgImage>
      <Container>
        <p className="lead orangeBarBelow">
        We are experienced, passionate and innovative and we thrive on streamlining and managing corporate events anywhere your business needs to be. From beginning to end we give you commitment, thoughtfulness, kindness and swift response. We share the sincere desire to support you and your event from initiation to achievement.
        </p>
      </Container>
      <Container className="singleCol">
        <p><strong>Personalized service, maximizing return on investment, and producing a lasting relationship that is loads of fun – that’s the ECI difference.</strong></p>
        <p>Event Consulting International, Inc. is a full-service event, meeting and incentive management company specializing in creating impactful experiences that deliver measurable results that your audience and employees will remember long after it ends. From site selection and logistics to marketing, event website development and content strategy, our expert advisors work with you to create the perfect program that achieves your goals.</p>
        <p>Kyle and Kalen – owners of ECI – and their professional team are experienced, passionate and innovative people who thrive on streamlining and managing corporate events anywhere your business needs to be. From beginning to end we give you commitment, thoughtfulness, kindness and swift response. We share the sincere desire to support you and your event from initiation to achievement</p>
        <p className="text-center">
          <StaticImage
              src="../images/kalen_kyle_eci.jpg"
              width={420}
              quality={72}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Corporate Meeting"
              className="rounded"
            />
            <p className="small">Kalen (left) & Kyle (right) Donaldson</p>
        </p>
        <p><strong>Kalen Donaldson.</strong> Kalen has a talent for combining your ideas and desires with details needed to create a solid event. She has a genuine, kind, and sincere manner of serving you, your organization, and the attendees of your event. Her effective negotiation skills and her relationships with vendors, hoteliers and trusted industry sources means that the whole team will work together to meet and beat your budgetary requirements with the creativity to make your meeting new, unique and extraordinary every time.</p>
        <p><strong>Kyle Donaldson. </strong>Kyle delivers over two decades of experience masterminding meeting, event and incentive planning. She has an extraordinary sense of what you need before you know you need it and is thinking ahead for you to present options that you will love. Once you experience her consideration and devotion, you will trust her with the full scope of your logistics so you can focus on creating excellent meeting content.</p>
        
      </Container>
      {/* <BgImage image={crowdImage}>
        Test
        <div className="color-overlay d-flex py-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <p className="lead"><em>"Safely Processing and Delivering Superior Value and Service to Our Customers for over 100 years."</em></p>
            </Container>
          </div>
        </div>
      </BgImage> */}
      {/* crowd.jpg */}
    </Layout>
          
  )
}

export default IndexPage2